@import 'src/styles/vars.scss';

.slick {
  &-list {
    margin: 0 -15px;
    overflow: visible;
  }

  &-slide > div {
    padding: 0 15px;
  }

  &-dots {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: -1;

    li {

      &.slick-active {
        width: 33px;
        
        button {

          &:after {
            width: 100%;
            background-color: #10FF90;
            border-radius: 6px;
          }

        }
      }

      button {
        width: 100%;
        height: 100%;
        position: relative;

        &:before {
          content: none;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%)translateY(-50%);
          margin: 0 auto;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          border: 1px solid #10FF90;
          padding: 0;
          transition: .3s ease;
        }



      }
    }
  }
}

