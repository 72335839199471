$rubik: "Rubik", sans-serif;
$micra: "Micra", sans-serif;

//colors
$dark: #0f0f0f;
$mainGreen: #1fbf75;
$poisonGreen: #10ff90;
$blueGradient: linear-gradient(360deg, #2a92ed 0%, #2a92ed 0.01%, #2b64f8 100%);
$radialGradient: radial-gradient(
  100% 100% at 0% 0%,
  rgba(255, 255, 255, 0.4) 0%,
  rgba(255, 255, 255, 0) 100%
);
$headerHeight: 90px;
$tabletHeaderHeight: 80px;
$mobHeaderHeight: 70px;

@mixin grayBlock {
  background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(42px);
  border-radius: 20px;
  border: 1px solid #666666;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}