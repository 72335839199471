@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');

@font-face {
    font-family: 'Micra';
    src: url('/media/fonts/Micra.woff2') format('woff2'),
        url('/media/fonts/Micra.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
